/* eslint-disable no-console */

import { register } from 'register-service-worker'

if ('serviceWorker' in navigator) {
  let sw = process.env.NODE_ENV === 'production' ? 'service-worker.js' : 'service-worker-dev.js';
  let scope = process.env.BASE_URL;
  
  navigator.serviceWorker.register(process.env.BASE_URL + sw, { scope: scope}).then((registration) => {
    console.log('Registrou service worker');
  });
}

//if (process.env.NODE_ENV === 'production') {
  // register(`/service-worker.js`, { scope: '/' }, {
  //   ready () {
  //     console.log(
  //       'App is being served from cache by a service worker.\n' +
  //       'For more details, visit https://goo.gl/AFskqB'
  //     )
  //   },
  //   registered (swRegistration) {
  //     console.log('Service worker has been registered.')
  //     registration = swRegistration;
  //   },
  //   cached () {
  //     console.log('Content has been cached for offline use.')
  //   },
  //   updatefound () {
  //     console.log('New content is downloading.')
  //   },
  //   updated () {
  //     console.log('New content is available; please refresh.')
  //   },
  //   offline () {
  //     console.log('No internet connection found. App is running in offline mode.')
  //   },
  //   error (error) {
  //     console.error('Error during service worker registration:', error)
  //   }
  // })
//}