import axios from "axios";
import Vue from "vue";
import router from "@/router";
import userTokenHelper from "@/helpers/UserTokenHelper.js";
import i18n from "@/i18n";

const webClient = axios.create({
  baseURL: process.env.VUE_APP_SISBRAIP_WEB_URL,
});
const authClient = axios.create({
  baseURL: process.env.VUE_APP_SISBRAIP_AUTH_URL,
});
const auditBoardingClient = axios.create({
  baseURL: process.env.VUE_APP_SISBRAIP_AUDIT_BOARDING_URL,
});
const inspectionClient = axios.create({
  baseURL: process.env.VUE_APP_SISBRAIP_INSPECTION_URL,
});
const billingClient = axios.create({
  baseURL: process.env.VUE_APP_SISBRAIP_BILLING_URL,
});

const pushClient = axios.create({
  baseURL: process.env.VUE_APP_SISBRAIP_PUSH_URL,
})

const USER_TOKEN = "user-token";

configureInterceptors(webClient);
configureInterceptors(authClient);
configureInterceptors(auditBoardingClient);
configureInterceptors(inspectionClient);
configureInterceptors(billingClient);
configureInterceptors(pushClient);

function configureInterceptors(client) {
  client.interceptors.request.use((req) => {
    //console.log("request interceptor");
    Vue.prototype.$Progress.start();

    addTokenIntoRequest(req);
    addLocaleIntoRequest(req);

    return req;
  });

  client.interceptors.response.use(
    (resp) => {
      //console.log("response interceptor");
      Vue.prototype.$Progress.finish();

      saveResponseToken(resp);

      return resp;
    },
    (error) => {
      // console.log(error.response);
      Vue.prototype.$Progress.fail();

      switch (error.response.status) {
        case 400: //BAD_REQUEST
          // beans validations messages
          Vue.prototype.$toast.error(error.response.data.messages.join("<br>"));
          break;

        case 401: //UNAUTHORIZED
          Vue.prototype.$toast.warning("Sessão expirou!");
          userTokenHelper.saveReturnUrl(router.currentRoute.fullPath);
          userTokenHelper.removeUserToken();
          router.push({ name: "login" });
          break;

        case 403: //FORBIDDEN
          router.push({
            name: "forbidden",
            query: { url: window.location.href },
          });
          break;

        case 404: //NOT_FOUND
          router.push({ name: "404", query: { url: window.location.href } });
          break;

        case 422: //UNPROCESSABLE_ENTITY
          var message = Array.isArray(error.response.data)
            ? error.response.data.join("<br>")
            : error.response.data;
          Vue.prototype.$toast.error(`${error.response.status} - ${message}`);
          break;

        case 444: //INTERNAL_SERVER_ERROR
          Vue.prototype.$toast.error("Já existe uma entidade com esses dados.");
          break;

        case 500: //INTERNAL_SERVER_ERROR
          Vue.prototype.$toast.error(
            "Erro interno do servidor, a equipe técnica foi notificada"
          );
          break;

        case 503: //SERVICE_UNAVAILABLE
          //TODO show service name
          Vue.prototype.$toast.error(error);
          break;

        default:
          // any other error
          Vue.prototype.$toast.error(error);
          break;
      }

      return Promise.reject({ error });
    }
  );
}

function addTokenIntoRequest(req) {
  if (userTokenHelper.hasValidToken()) {
    // just sends jwt token if its valid
    const token = localStorage.getItem(USER_TOKEN);
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
      req.headers["Access-Control-Allow-Origin"] = "*";
    }
  }
}

function addLocaleIntoRequest(req) {
  const locale = i18n.locale;
  if (locale) {
    req.headers["Accept-Language"] = locale;
  }
}

function saveResponseToken(resp) {
  const token = resp.headers["token"];
  if (token) {
    localStorage.setItem(USER_TOKEN, token);
  }
}

export default {
  webClient,
  authClient,
  auditBoardingClient,
  inspectionClient,
  billingClient,
  pushClient
};
