export default [
  // Flights urls
  {
    path: "flights",
    name: "flights",
    meta: { text: "searchFlight" },
    component: () => import("@/views/boarding/flight/Flights.vue"),
  },
  {
    path: "airline-flights",
    name: "airline-flights",
    component: () => import("@/views/boarding/flight/AirlineFlights.vue"),
  },
  {
    path: "flights/:flightId/boardingPanel",
    name: "flight-boarding-panel",
    component: () =>
      import("@/views/boarding/flightBoardingPanel/FlightBoardingPanel.vue"),
  },
  {
    path: "flights/:flightId/airline-boarding-panel",
    name: "airline-flight-boarding-panel",
    component: () =>
      import(
        "@/views/boarding/flightBoardingPanel/AirlineFlightBoardingPanel.vue"
      ),
  },
  // Passengers urls
  {
    path: "flights/:flightId/passengers",
    name: "flight-passengers",
    meta: { text: "flightPassengers" },
    component: () =>
      import("@/views/boarding/flightPassenger/FlightPassengers.vue"),
  },
  {
    path: "flights/:flightId/passengers-inspected",
    name: "flight-passengers-inspected",
    component: () =>
      import("@/views/boarding/flightPassenger/FlightPassengersInspected.vue"),
  },
  {
    path: "flights/:flightId/passengerList/:columnsToPrint",
    name: "flight-passenger-list",
    component: () => import("@/views/boarding/passengerList/PassengerList.vue"),
  },
  {
    path: "flights/passengers/:flightPassengerId",
    name: "flight-passenger",
    component: () =>
      import("@/views/boarding/flightPassenger/FlightPassengerDetail.vue"),
    children: [
      {
        path: "details",
        name: "flight-passenger-details",
        meta: { text: "passengerDetails" },        
        component: () =>
          import("@/views/boarding/components/FlightPassengerDetails.vue"),
      },
      {
        path: "events",
        name: "flight-passenger-events",
        component: () =>
          import("@/views/boarding/components/FlightPassengerEvents.vue"),
      },
      {
        path: "ocurrencies",
        name: "flight-passenger-ocurrencies",
        component: () =>
          import("@/views/boarding/components/PassengerFlightOcurrencies.vue"),
      },
      {
        path: "alerts",
        name: "flight-passenger-alerts",
        component: () =>
          import("@/views/boarding/components/PassengerFlightAlerts.vue"),
      },
    ],
  },
  {
    path: "vu/search-vus",
    name: "search-vus",
    meta: { text: "uniqueTraveler" },    
    component: () => import("@/views/boarding/vu/SearchVU.vue"),
  },
  {
    path: "vu/search-vu/:vuId",
    name: "search-vu-detail",
    component: () => import("@/views/boarding/vu/SearchVUDetail.vue"),
    children: [
      {
        path: "details",
        name: "vu-details",
        meta: { text: "uniqueTraveler" },
        component: () => import("@/views/boarding/vu/components/VUDetails.vue"),
      },
      {
        path: "all-flights",
        name: "vu-all-flights",
        meta: { text: "uniqueTraveler" },
        component: () =>
          import("@/views/boarding/vu/components/VUAllFlights.vue"),
      },
      {
        path: "all-flights-inspection",
        name: "vu-all-flights-inspection",
        meta: { text: "uniqueTraveler" },
        component: () =>
          import("@/views/boarding/vu/components/VUAllFlightsInspection.vue"),
      },
      {
        path: "restrictions",
        name: "vu-restrictions",
        meta: { text: "uniqueTraveler" },
        component: () =>
          import("@/views/boarding/vu/components/VURestrictions.vue"),
      },
      {
        path: "occurencies",
        name: "vu-occurencies",
        meta: { text: "uniqueTraveler" },
        component: () =>
          import("@/views/boarding/vu/components/VUOccurencies.vue"),
      },
      {
        path: "alerts",
        name: "vu-alerts",
        meta: { text: "uniqueTraveler" },
        component: () =>
          import("@/views/boarding/vu/components/VUAlerts.vue"),
      },
    ],
  },
  {
    path: "custom-query/list",
    name: "list-custom-query",
    component: () => import("@/views/boarding/query/ListCustomQuery.vue"),
  },
  {
    path: "custom-query/:id?",
    name: "custom-query",
    component: () => import("@/views/boarding/query/CustomQuery.vue"),
  },
  {
    path: "crews",
    name: "crews",
    component: () => import("@/views/boarding/crew/CrewList.vue"),
  },
  {
    path: "crews/:crewId",
    name: "crew-detail",
    component: () => import("@/views/boarding/crew/CrewDetail.vue"),
  },
  {
    path: "itinerarySearch",
    name: "itinerary-search",
    component: () => import("@/views/boarding/itinerary/ItinerarySearch.vue"),
  },
];
