<template>
  <v-app>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>


    <!--App Bar desktop e página inicial PWA -->
    <template v-if="showAppBar">
      <!-- menu lateral -->      
      <v-navigation-drawer
        class="d-print-none"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :expand-on-hover="miniMenu"
        :mini-variant="miniMenu"
        v-model="showMenu"
        app
        color="#243fa0"
        dark
      >
        <Menu :menuItens="menuItens" />
      </v-navigation-drawer>

      <!--Header-->
      <v-app-bar
        class="d-print-none"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="white"
        light
      >
        <v-app-bar-nav-icon @click.stop="handleMenu()" />

        <v-spacer v-if="isPwa"/>
        <router-link to="/" class="mt-3">
          <img v-if="sisbraip"
            src="@/assets/img/sisbraip-hor.svg"
            alt="Embarque Seguro"
            class="mb-2 app-logo"
          />
          <img v-else
            src="@/assets/logo-embarquemaisseguro.svg"
            alt="Embarque Seguro"
            class="mb-2 app-logo"
          />
        </router-link>

        <v-spacer />

        <span class="d-none d-sm-block">
          <v-menu left :offset-y="true">
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                class="subtitle-1 text-capitalize font-weight-light"
              >
                {{ currentLanguage.name }}
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group v-model="currentLanguageIndex">
                <v-list-item
                  v-for="(item, index) in languages"
                  :key="index"
                  @click="clickChangeLanguage(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </span>
        <template v-if="showAlerts && !isPwa">
        <v-badge color="pink" overlap class="mx-3 hidden-sm-and-down" :content="alertCount" v-if="alertCount > 0">
          <v-btn icon small @click="navigateToAlerts()">
            <v-icon >mdi-bell</v-icon>
          </v-btn>
        </v-badge>
        <v-btn icon small @click="navigateToAlerts()" v-else>
          <v-icon >mdi-bell</v-icon>
        </v-btn>
      </template>

        <v-menu bottom left :offset-y="true" :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              v-on="on"
              class="subtitle-1 text-capitalize font-weight-light"
            >
              <v-avatar v-if="isPwa" color="primary" size="35">
                <span class="white--text text-subtitle-1">{{ userInitials }}</span>
              </v-avatar>  
              <span v-else>{{ userName | truncate(15, "...") }}</span>
              <v-icon right>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("account") }}</v-list-item-title>
                <v-list-item-subtitle>{{ userName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-title>{{ $t('languages')}}</v-list-item-title>
              </template>
              <v-list-item :input-value="currentLanguageIndex == index"
                v-for="(item, index) in languages"
                :key="index"
                @click="clickChangeLanguage(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-divider></v-divider>          

            <v-list-item link @click="myAccount()">
              <v-list-item-icon class="mx-1">
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("myAccount") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="logout()">
              <v-list-item-icon class="mx-1">
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("exit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </template>
    <!--Fim app Bar desktop e página inicial PWA -->    

    <!--App Bar páginas PWA (exceto página inicial) -->
    <template v-else>
      <!-- menu lateral -->      
      <v-navigation-drawer
        class="d-print-none"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :expand-on-hover="miniMenu"
        :mini-variant="miniMenu"
        v-model="showMenu"
        app
        color="#243fa0"
        dark
      >
        <Menu :menuItens="menuItens" />
      </v-navigation-drawer>

      <v-card>
        <v-app-bar app tile elevation="8" elevate-on-scroll color="#254FAA" dark>
          <v-btn
            icon
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-app-bar-nav-icon @click.stop="handleMenu()" />
          <v-toolbar-title>{{ $t(currentRouteText) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-chip 
            v-if="showAlertsInAppBar"
            class="mr-2"
            color="#F93A6E"
            text-color="white"
          >
            <span class="text-subtitle-1">{{ alertCount }}</span>
          </v-chip>

          <template v-if="currentTabsComponent" v-slot:extension>
            <component :is="currentTabsComponent"/>
          </template>
        </v-app-bar>
      </v-card>
    </template>
    <!-- Fim app bar páginas PWA (exceto página inicial) -->    

    <v-main class="grey lighten-4">
      <v-container fluid>
        <UserWithoutFunctionality v-if="userWithoutFunctionality" />
        <router-view v-else @changeAlertCount="changeAlertCount" @setTabsComp="setTabsComp"></router-view>
      </v-container>
    </v-main>

    <Footer v-if="!isPwa"/>
  </v-app>
</template>

<script>
import Footer from "@/layouts/Footer.vue";
import userTokenHelper from "@/helpers/UserTokenHelper.js";
import GovBrService from "@/services/GovBrService.js";
import { LanguageMixin } from "@/mixins/LanguageMixin.js";
import { AdminMenuMixin } from "@/mixins/AdminMenuMixin.js";
import { PwaHelperMixin } from "@/mixins/PwaHelperMixin.js";
import UserWithoutFunctionality from "@/components/UserWithoutFunctionality.vue";
import Menu from "@/layouts/Menu.vue";
import userAlert from "@/helpers/UserAlerts.js";

export default {
  mixins: [LanguageMixin, AdminMenuMixin, PwaHelperMixin ],
  components: { Footer, UserWithoutFunctionality, Menu },

  data: () => ({
    alerts : null,
    alertCount: 0,
    user: null,
    userWithoutFunctionality: true,
    overlay: false,
    miniMenu: null,
    showMenu: null,
    showAlerts: false,
    languages: [
      { code: "pt", name: "Português" },
      // { code: "es", name: "Español" },
      { code: "en", name: "English" },
    ],
    currentLanguage: {},
    currentLanguageIndex: 0,
    currentTabsComponent: null,
    currentTabsRoute: null
  }),

  created() {
    this.user = userTokenHelper.getUser();
    this.userWithoutFunctionality = this.menuItens.length == 0;
    const currentLanguageCode = this.getCurrentLanguageCode();
    this.changeLanguage(currentLanguageCode);
    this.miniMenu = localStorage.miniMenu == "true" ? true : false;
    userAlert.allowAlerts().then(ret =>{
        this.showAlerts = ret.data;
    });
    userAlert.register(this.changeAlertCount);
    userAlert.subscribeToEvents();
  },
  watch : {
    $route(val) {
      if (!val.matched.includes(this.currentTabsRoute)) {
        this.currentTabsComponent = null;
        this.currentTabsRoute = null;
      }
    }
  },
  methods: {
    async navigateToAlerts() {
      let url = {
        name: "alerts",
      };
      await this.$router.push(url);
    },
    
    /**
     * @languageCode: ex: pt
     */
    changeLanguage(languageCode) {
      this.configureLanguage(languageCode);

      this.currentLanguage = this.getLanguageByCode(languageCode);
      this.currentLanguageIndex = this.languages.indexOf(this.currentLanguage);
    },
    getLanguageByCode(languageCode) {
      const language = this.languages.filter((l) => l.code == languageCode);
      return language[0];
    },
    clickChangeLanguage(item) {
      this.changeLanguage(item.code);
      localStorage.lang = item.code;
    },
    handleMenu() {
      if (this.$vuetify.breakpoint.mobile) {
        this.showMenu = true;
        this.miniMenu = false;
      } else {
        this.miniMenu = !this.miniMenu;
        localStorage.miniMenu = this.miniMenu;
      }
    },
    logout() {
      GovBrService.logout().then(
        (r) => {
          userTokenHelper.removeUserToken();
          this.overlay = true;
          window.location = r.data;
        },
        () => {}
      );
    },
    myAccount() {
      GovBrService.myAccount().then(
        (r) => {
          window.open(r.data);
        },
        () => {}
      );
    },
    changeAlertCount(count) {
      this.alertCount = count;
    },
    setTabsComp(tabs) {
      this.currentTabsComponent = tabs.component;
      this.currentTabsRoute = tabs.route;
    }
  },
  computed: {
    menuItens() {
      return this.buildMenu(this.user, this.showAlerts);
    },
    userName() {
      return userTokenHelper.getUserName();
    },
    sisbraip() {
      return window.location.host.includes("sisbraip");
    },
    userInitials() {
      let userName = this.userName;
      let words = userName.split(' ');
      let initials = words.map(word => word.substring(0, 1).toUpperCase());
      return initials.join('');
    },
    showAppBar() {
      return this.$route.name === 'home' || !this.isPwa;
    },
    currentRouteText() {
      return this.$route.meta["text"] ?? "";
    },
    showAlertsInAppBar() {
      return this.alertCount > 0 && this.$route.name === 'alerts';
    }
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style lang="scss">

.app-logo {
  width: 170px;
}

@media screen and (max-width:500px) {
  .app-logo {
    width: 150px;
  }
}

@media print {
  .v-main {
    padding: 0 !important;
  }
}

.active-menu-item {
  color: white !important;
  background: rgba(255, 255, 255, 0.1);
}
.account-menu-link {
  text-transform: none !important;
}

</style>
