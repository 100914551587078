import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "@/views/PageNotFound.vue";
import Forbidden from "@/views/Forbidden.vue";
import Admin from "@/layouts/Admin.vue";
import App from "@/App.vue";
import auth from "./auth.js";
import admin from "./admin.js";
import boarding from "./boarding.js";
import integrator from "./integrator.js";
import inspection from "./inspection.js";
import reports from "./reports.js";
import billing from "./billing.js";
import updates from "./updates.js";
import help from "./help.js";
import userTokenHelper from "@/helpers/UserTokenHelper.js";
import permissions from "@/helpers/Permissions.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: redirectInitPage,
  },
  {
    path: "/auth",
    component: App,
    children: auth,
  },
  {
    path: "/lgpd/:id",
    component: App,
    children: [
      {
        path: "",
        name: "lgpd",
        component: () => import("@/views/Lgpd.vue"),
      },
    ],
  },
  {
    path: "/home",
    component: Admin,
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/home/Home.vue"),
      },
    ],
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/version",
    component: Admin,
    children: [
      {
        path: "",
        name: "version",
        component: () => import("@/views/Version.vue"),
      },
    ],
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/admin",
    component: Admin,
    children: admin,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/boarding",
    component: Admin,
    children: boarding,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/integrator",
    component: Admin,
    children: integrator,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/inspection",
    component: Admin,
    children: inspection,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/reports",
    component: Admin,
    children: reports,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/billing",
    component: Admin,
    children: billing,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),
  },
  {
    path: "/updates",
    component: Admin,
    children: updates,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),    
  },
  {
    path: "/help",
    component: Admin,
    children: help,
    beforeEnter: (to, from, next) => securedRoute(to, from, next),    
  },
  {
    name: "404",
    path: "/404",
    component: PageNotFound,
  },
  {
    name: "forbidden",
    path: "/forbidden",
    component: Forbidden,
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

function securedRoute(to, from, next) {
  if (!userTokenHelper.hasValidToken()) {
    let isExpired = userTokenHelper.getUser();
    userTokenHelper.removeUserToken();
    userTokenHelper.saveReturnUrl(to.fullPath);
    next({ name: "login", params: isExpired ? {expiredSession: true} : null});
  } else {
    next();
  }
}

function redirectInitPage() {
  let user = userTokenHelper.getUser();
  if (user?.is_airline && user.groups.includes(permissions.AIRLINE_FLIGHT_LIST)) {
    return { name: "airline-flights" }
  } else {
    return { name: "home" };
  }
}

const router = new VueRouter({
  mode: "history",
  // contexto da aplicação
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name && to.name == 'login-return') {
    next({ name: 'home'});
  } else {
    next();
  }
});

export default router;
