export default [
  {
    path: "role/list",
    name: "list-role",
    component: () => import("@/views/admin/role/ListRole.vue"),
  },
  {
    path: "role/add",
    name: "add-role",
    component: () => import("@/views/admin/role/AddRole.vue"),
  },
  {
    path: "role/edit/:id",
    name: "edit-role",
    component: () => import("@/views/admin/role/EditRole.vue"),
  },
  {
    path: "user/list",
    name: "list-user",
    component: () => import("@/views/admin/user/ListUser.vue"),
  },
  {
    path: "user/add",
    name: "add-user",
    component: () => import("@/views/admin/user/AddUser.vue"),
  },
  {
    path: "user/import",
    name: "import-user",
    component: () => import("@/views/admin/user/ImportUser.vue"),
  },
  {
    path: "user/edit/:id",
    name: "edit-user",
    component: () => import("@/views/admin/user/EditUser.vue"),
  },
  {
    path: "audit/list-api-audit",
    name: "list-api-audit",
    component: () => import("@/views/admin/audit/ListApiAudit.vue"),
  },
  {
    path: "api-pnr/list-api-pnr",
    name: "list-api-pnr",
    component: () => import("@/views/admin/apiPnrPanel/ListApiPnrPanel.vue"),
  },
  {
    path: "audit/api-audit/:id",
    name: "detail-api-audit",
    component: () => import("@/views/admin/audit/DetailApiAudit.vue"),
  },
  {
    path: "organization/list",
    name: "list-organization",
    component: () => import("@/views/admin/organization/ListOrganization.vue"),
  },
  {
    path: "organization/add",
    name: "add-organization",
    component: () => import("@/views/admin/organization/AddOrganization.vue"),
  },
  {
    path: "organization/edit/:id",
    name: "edit-organization",
    component: () => import("@/views/admin/organization/EditOrganization.vue"),
  },
  {
    path: "airport/list",
    name: "list-airport",
    component: () => import("@/views/admin/airport/ListAirport.vue"),
  },
  {
    path: "airport/add",
    name: "add-airport",
    component: () => import("@/views/admin/airport/AddAirport.vue"),
  },
  {
    path: "airport/edit/:id",
    name: "edit-airport",
    component: () => import("@/views/admin/airport/EditAirport.vue"),
  },
  {
    path: "airline/list",
    name: "list-airline",
    component: () => import("@/views/admin/airline/ListAirline.vue"),
  },
  {
    path: "airline/add",
    name: "add-airline",
    component: () => import("@/views/admin/airline/AddAirline.vue"),
  },
  {
    path: "airline/edit/:id",
    name: "edit-airline",
    component: () => import("@/views/admin/airline/EditAirline.vue"),
  },
  {
    path: "user-airport/list",
    name: "list-user-airport",
    component: () => import("@/views/admin/user_airport/ListUserAirport.vue"),
  },
  {
    path: "user-airport/edit/:id",
    name: "edit-user-airport",
    component: () => import("@/views/admin/user_airport/EditUserAirport.vue"),
  },
  {
    path: "organization-airport/list",
    name: "list-organization-airport",
    component: () =>
      import("@/views/admin/organization_airport/ListOrganizationAirport.vue"),
  },
  {
    path: "organization-airport/edit/:id",
    name: "edit-organization-airport",
    component: () =>
      import("@/views/admin/organization_airport/EditOrganizationAirport.vue"),
  },
  {
    path: "country/list",
    name: "list-country",
    component: () => import("@/views/admin/country/ListCountry.vue"),
  },
  {
    path: "country/add",
    name: "add-country",
    component: () => import("@/views/admin/country/AddCountry.vue"),
  },
  {
    path: "country/edit/:id",
    name: "edit-country",
    component: () => import("@/views/admin/country/EditCountry.vue"),
  },
  {
    path: "alerts",
    name: "alerts",
    meta: { text: "alerts" },
    component: () => import("@/views/alerts/UserAlerts.vue"),
  },
];
